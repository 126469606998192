<template>
  <div class="brandHead">
    <div class="brandThumbGallaryBox">
      <ul class="brandThumbGallary">
        <li v-for="(img, index) in imgs">
          <img :src="img.img_url_min" />
        </li>
      </ul>
    </div>
    <div class="brandHeadInner">
      <div class="brandHeadInnerBox">
        <p class="brandHeadName">
          <span class="brandEn">{{brands.name_en}}</span>
          <span class="brandJa">{{brands.name_ja}}</span>
        </p>
        <div class="brand__head--btn">
          <BrandBtn :brand_id="brands.id" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Mixin} from '@/mixins/common'
import BrandBtn from "@/components/parts/BrandBtn.vue";

export default {
  props: ['brands', 'imgs'],
  data() {
    return {
    }
  },
  components: {
    BrandBtn
  },
  mixins:[Mixin],
  methods: {
    refresh(){
      this.imgs = {}
    },
  }
}
</script>

<style>
.brandHead{
  position: relative;
  margin-bottom: 40px;
}

.brandHeadInner{
  z-index: 2;
  bottom: -40px;
  position: absolute;
  width: 100%;
  padding: 16px;
}

.brandHeadInnerBox{
  background-color: rgba(255,255,255,.9);
  border-radius: 6px;
  padding: 16px;
  /* border: 1px solid #FAFAFA; */
  box-shadow:0px 1px 3px .5px #EEE;
}

.brandHeadName{
  margin: 0 0 16px;;
  text-align: center;
}

.brandTxt{
  font-size: .9rem;
}

.brandThumbGallaryBox{
 height: 180px;
 position: relative;
 display: flex;
 align-items: center;
 overflow: hidden;
 z-index: 1;
}

.brandThumbGallary{
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
}

.brandThumbGallaryBox:after{
  display: block;
  content: '';
  background: rgba(255,255,255,.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.brandThumbGallary li{
  min-width: 20%;
  max-width: 20%;
  height: 90px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.brandThumbGallary li img{
  width: 100%;
}

.brand__head--btn{
  display: block;
  text-align: center;
}

.brand__head--btn .brand__link--btn{
  position: relative;
  right: auto;
  top: auto;
  bottom: auto;
  justify-content: center;
  padding: 0;
}
</style>
