<template>
  <div class="gird">
    <Head v-bind:data="HeadSetting" />
    <section class="container">
      <brandHead v-bind:brands="brands" :imgs="imgs"/>
      <PageNav v-bind:pageNav="pageNav"/>
      <div class="brand__top__frame">
        <transition>
          <router-view :brandCode="brandCode" />
        </transition>
      </div>
    </section>
  </div>
</template>
 e
<script>
import Head from "@/components/block/Head.vue";
import brandHead from "@/components/parts/brandHead.vue";
import PageNav from "@/components/block/PageNav.vue";
import {Mixin} from '@/mixins/common'
import axios from 'axios';

export default {
  name: 'BrandFrame',
  data() {
    return {
      brands: {},
      brandCode: '',
      imgs: {},
      HeadSetting: {
        Left: 'backBtn',
        Right: 'Share',
        Center: 'search'
      },
      pageNav: {}
    };
  },
  components: {
    brandHead,
    PageNav,
    Head
  },
  mixins:[Mixin],
  created () {
    this.refresh()
    this.pageInit(this.$route.params.code)
    this.getData()
    this.getImgs()
  },
  mounted () {
  },
  beforeRouteUpdate(to, from, next) {
    // console.log('brand before route update');

    if(to.params.code != from.params.code){
      this.refresh()
      this.pageInit(to.params.code)
      this.getData()
      this.getImgs()
    }
    next()
  },
  computed: {
    getBrandCode: function(){
      return this.brandCode
    }
  },
  methods: {
    refresh(){
      this.brands = {}
      this.pageNav = {}
      this.brandCode = ''
      this.imgs = {}
    },
    pageInit(code){
      this.brandCode = code
      this.pageNav = {
        lists: [
          {
            name: 'categories',
            route: {name: 'BrandCategories', params: {code: code}},
            title: 'カテゴリ',
            scrollTo: '#brandNav',
            exact: false,
          },
          {
            name: 'items',
            route: {name: 'BrandTop', params: {code: code}},
            title: '新着アイテム',
            scrollTo: '#app',
            exact: true,
          },
          {
            name: 'stores',
            route: {name: 'BrandStoreTop', params: {code: code}},
            title: 'お店を探す',
            scrollTo: '#brandNav',
            exact: false,
          }
        ],
        id: 'brandNav'
      }
    },
    async getData(){
      const url = this.api + '/brands/' + this.brandCode

      await axios.get(url)
      .then((res) => {
        this.brands = res.data
      })
      .catch((e) =>{
        // console.log(e);
        // console.log('brand not found')
      })
    },
    async getImgs(){
      const url = this.api + '/brands/' + this.brandCode + '/imgs.json'
      // console.log(url);
      await axios.get(url)
      .then((res) => {
        this.imgs = res.data
      })
      .catch((e) =>{
        console.log(e);
        console.log('item imgs not found')
      })
    },
  }
}
</script>

<style scoped>
.brand__top__frame{
  padding-bottom: 80px;
  min-height: 100vh;
}
</style>
